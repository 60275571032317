import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import RecsFormat from '../../../components/RecsFormat';
import "./Secured.css"
// Comment the above and uncomment the following to import the WebGL BG lazily for faster loading times
// const Bananas = lazy(() => import('./Bananas'))

function Secured() {
  const API_URL = import.meta.env.VITE_API_URL;

  const { isAuthenticated } = useAuth0();
  const {user} = useAuth0();
  const [recsList, setRecsList] = useState([]);
  const [highestRatedMedia, setHighestRatedMedia] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecs = async () => {
      try {
        const currUserResponse = await axios.post(`${API_URL}/user/`, user);
        const recs = await axios.get(`${API_URL}/user/chat/${currUserResponse.data[0].id}`);
        const highestRated = await axios.get(`${API_URL}/user/highest-rated/`);
        setHighestRatedMedia(highestRated.data);
        setRecsList(recs.data ? recs.data : []);
      } catch (err) {
        console.log("error getting user data");
      }
    }
    console.log(location)
    fetchRecs();
  },[])

  const handleClick = (result: any) => {
    navigate(`/media/${result.title}/${result.mediaType}`, {
      state: {
        result: result
      }
    });
  };

  return (
    isAuthenticated && (
      <div className="the-secured-page">
        <RecsFormat onClick={handleClick} recsList={highestRatedMedia} mediaType={"Book"} />
        <RecsFormat onClick={handleClick} recsList={highestRatedMedia} mediaType={"TV Show"} />
        <RecsFormat onClick={handleClick} recsList={highestRatedMedia} mediaType={"Movie"} />
        <RecsFormat onClick={handleClick} recsList={highestRatedMedia} mediaType={"Video Game"} />
        <Box sx={{ flexGrow: 1 }}>
        <p className="fave-titles">Movies For You</p>
        <hr className="main-divider" />
          <Grid container spacing={2} minHeight={160}>
            {recsList.map((rec: any) => {
              return rec.mediaType === "Movie" && (
                  <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                    <div className="media-home-page">
                    <img onClick={() => handleClick(rec)} className='content-item img-size' src={rec?.thumbnail} />
                    {/* <br /> */}
                    <p onClick={() => handleClick(rec)}>{rec.mediaTitle}</p>
                    </div>
                  </Grid>
            )
            })}
          </Grid>
        </Box>
        <br />
        <Box sx={{ flexGrow: 1 }}>
        <p className="fave-titles">Video Games For You</p>
        <hr className="main-divider" />
          <Grid container spacing={2} minHeight={160}>
            {recsList.map((rec: any) => {
              return rec.mediaType === "Video Game" && (
                  <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                    <div className="media-home-page">
                    <img onClick={() => handleClick(rec)} className='content-item img-size' src={rec?.thumbnail} />
                    {/* <br /> */}
                    <p onClick={() => handleClick(rec)}>{rec.mediaTitle}</p>
                    </div>
                  </Grid>
            )
            })}
          </Grid>
        </Box>
        <br />

        <Box sx={{ flexGrow: 1 }}>
        <p className="fave-titles">TV Shows For You</p>
        <hr className="main-divider" />
          <Grid container spacing={2} minHeight={160}>
            {recsList.map((rec: any) => {
              return rec.mediaType === "TV Show" && (
                  <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                    <div className="media-home-page">
                    <img onClick={() => handleClick(rec)} className='content-item img-size' src={rec?.thumbnail} />
                    {/* <br /> */}
                    <p onClick={() => handleClick(rec)}>{rec.mediaTitle}</p>
                    </div>
                  </Grid>
            )
            })}
          </Grid>
        </Box>
        <br />
        <Box sx={{ flexGrow: 1 }}>
        <p className="fave-titles">Books For You</p>
        <hr className="main-divider" />
          <Grid container spacing={2} minHeight={160}>
            {recsList.map((rec: any) => {
              return rec.mediaType === "Book" && (
                  <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                    <div className="media-home-page">
                    <img onClick={() => handleClick(rec)} className='content-item img-size' src={rec?.thumbnail} />
                    {/* <br /> */}
                    <p onClick={() => handleClick(rec)}>{rec.mediaTitle}</p>
                    </div>
                  </Grid>
            )
            })}
          </Grid>
        </Box>
      </div>
    )
  );
}

export default Secured;