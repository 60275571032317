export const uploadButton = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export const uploadReviewsButton = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  color: '#31628F',
  borderColor: '#31628F',
}